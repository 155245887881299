
var uberMenuScrollBreakpoint = 1199;

window.is_mac_os = function(){
    return (navigator.userAgent.indexOf("Macintosh")) > -1;
};

//
window.really_is_mac_os = function(){
    //platform check detects Inspect Element-manipulated user agent
    return window.navigator.platform == "MacIntel";
};
window.is_ipad = function(){
    return (navigator.userAgent.indexOf("iPad")) > -1;
};
window.is_firefox = function(){
    return (navigator.userAgent.indexOf("Firefox")) > -1;
};

//to avoid depending on script load order, use a function instead
window.usingCustomComponents = function(){
    return typeof(window.enableCustomComponents) !== 'undefined' && window.enableCustomComponents === true;
};

window.isSelect2Loaded = function(){
    return typeof($.fn.select2) !== 'undefined';
};



function debounce (func, threshold, execAsap) {
    var timeout;

    return function debounced () {
        var obj = this, args = arguments;
        function delayed () {
            if (!execAsap)
                func.apply(obj, args);
            timeout = null;
        };

        if (timeout)
            clearTimeout(timeout);
        else if (execAsap)
            func.apply(obj, args);

        timeout = setTimeout(delayed, threshold || 100);
    };
}

/*
returns value depending on screen width (can be passed or auto-detected).
e.g. when screen size is 1280, returns 9 (see below). sizes are bootstrap 4-style (576), + xxl >1440 size
e.g. responsiveValueFromList({
xxl: 9,
  xl: 9,
  lg: 8,
  md: 6,
  sm: 5,
  xs: 3
  }, 1280)
*/
function responsiveValueFromList(list, wScreen){
    var w = wScreen ? wScreen : $(window).width();
    if(1440 < w){
        return list.xxl;
    } else if(1200 <= w && w < 1440){
        return list.xl;
    } else if(992 <= w && w < 1200){
        return list.lg;
    } else if(768 <= w && w < 992){
        return list.md;
    } else if(576 <= w && w < 768){
        return list.sm;
    } else {//w < 576
        return list.xs;
    }
}

function trunk8ultimate(selector, lines, callback){
    var curLines = typeof(lines)==="object" ? responsiveValueFromList(lines) : lines;
    setTimeout(function(){
        $(selector)
            .trunk8({ lines: curLines, tooltip: false })
            .addClass('trunk8ed');
        if(typeof(callback) !== 'undefined'){
            callback.call(window);
        }
    }, 0);
    $(window).on('resize orientationchange', function() {
        var curLines = typeof(lines)==="object" ? responsiveValueFromList(lines) : lines;
        setTimeout(function(){
            $(selector)
                .trunk8({ lines: curLines, tooltip: false })
                .addClass('trunk8ed');
            if(typeof(callback) !== 'undefined'){
                callback.call(window);
            }
        }, 0);
    });
}

jQuery.fn.slickResponsive = function(slickOptions){
    var $o = $(this[0]);
    var slickInitFn = function(){
        $o.slick(slickOptions);
    };
    slickInitFn();
    $(window).on('resize orientationchange', debounce(function() {
        $o.slick('unslick');
        slickInitFn();
        $o.slick('resize');
    }, 100));
    return this;
}

//add classes faster - don't wait for doc.ready
if(window.is_mac_os()){
    //$("body").addClass("browser-mac-os");
    document.body.classList.add("browser-mac-os");
}
if(window.really_is_mac_os()){
    //$("body").addClass("platform-mac-os");
    document.body.classList.add("platform-mac-os");
}
if(window.is_ipad()){
    //$("body").addClass("browser-mac-os");
    document.body.classList.add("browser-ios");
    document.body.classList.add("browser-ipad");
}

if(window.is_firefox()){
    //$("body").addClass("browser-mac-os");
    document.body.classList.add("browser-firefox");
}

$(document).ready(function(){

    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover();

    // WARNING: applies both to header menu .short-menu and uber menu .short-menu
    //disabled bootstrap dropdown and made it work only on hover
    /*$('body').on('show.bs.dropdown', '.short-menu .dropdown', function(){
        return false;
        //$(this).siblings().addClass('other-is-active');
        //$(this).removeClass('other-is-active');
    });
    $('body').on('hide.bs.dropdown', '.short-menu .dropdown', function(){
        return false;
        //$(this).siblings().removeClass('other-is-active');
    });*/

    /*$('body').on('mouseenter', '.short-menu .dropdown', function(){
        $(this).siblings().addClass('other-is-active');
        $(this).removeClass('other-is-active');
    });
    $('body').on('mouseleave', '.short-menu .dropdown', function(){
        $(this).siblings().removeClass('other-is-active');
    });*/

    // we can use the selector with ">" is here so that it will only work outside of header uber menu
    //$('.header .header-main-col>.header-short-menu .short-menu>li.dropdown')
    //we'll do it with CSS
    /*$('.header .short-menu>li.dropdown').mouseenter(function(){
        $(this).addClass('show');
        $(this).find('.dropdown-menu').addClass('show');
        $(this).trigger('show.bs.dropdown');
    }).mouseleave(function(){
        $(this).removeClass('show');
        $(this).find('.dropdown-menu').removeClass('show');
        $(this).trigger('hide.bs.dropdown');
        });*/
    //
    //expand submenus on click (applies to UBER in all handheld devices; applies to MAIN SHORT MENU on iPad portrait)
    var selectorForShortMenuItemsOfHeaderANDUber = '.header-main-col>.header-short-menu .short-menu>li.dropdown, .header-uber-row-short-menu .short-menu>li.dropdown';
    $(selectorForShortMenuItemsOfHeaderANDUber).click(function(){
        if($(window).width() <= uberMenuScrollBreakpoint){
            $(this).toggleClass('show');
        }
    });
    //close open submenus when opening uber (otherwise MAIN SHORT MENU submenus and UBER overlap)
    $('.header-uber-toggle').click(function(){
        if($(window).width() <= uberMenuScrollBreakpoint){
            $(selectorForShortMenuItemsOfHeaderANDUber).removeClass('show');
        }
    });

    function openUber(){
        $('.header-uber').addClass('open');
        $('.header-uber-toggle').removeClass('collapsed');
        $('body').addClass('uber-is-open');
    }
    function closeUber(){
        $('.header-uber').removeClass('open');
        $('.header-uber-toggle').addClass('collapsed');
        $('body').removeClass('uber-is-open');
        //ensure both search popups are closed too (in header and in uber)
        $('.header-search').removeClass('open');
        $('body').removeClass('search-is-open');
    }
    function toggleUber(){
        if($('body').hasClass('uber-is-open')){
            closeUber();
        } else {
            openUber();
        }
    }

    $('body').on('click', '.header-uber-toggle', function(e){
        toggleUber();
        e.preventDefault();
        return false;
    });

    $('body').on('click', '.nav-tabs .nav-link', function(){
        $(this).parents('.nav-tabs').find('.nav-item,.nav-link').removeClass('active');
        $(this).parent('.nav-item').addClass('active');
        $(this).addClass('active');
    });

    $('body').on('click', '.header-search-toggle,.header-uber .header-search-title', function(e){
        if($('body').hasClass('uber-is-open')){
            //closeUber();
            $('.header-uber .header-search').addClass('open');
        } else {
            $('.header-main-col>.header-search').addClass('open');
        }
        $('body').addClass('search-is-open');
        e.preventDefault();
        return false;
    });
    $('body').on('click', '.header-search-close', function(e){
        $('.header-search').removeClass('open');
        $('body').removeClass('search-is-open');
        e.preventDefault();
        return false;
    });

    $('body').on('click', '.request-an-agent-toggle', function(e){
        $('.header-request-an-agent').addClass('open');
        $('body').addClass('request-an-agent-is-open');
        e.preventDefault();
        return false;
    });
    $('body').on('click', '.header-request-an-agent-close', function(e){
        $('.header-request-an-agent').removeClass('open');
        $('body').removeClass('request-an-agent-is-open');
        e.preventDefault();
        return false;
    });

    //var headerHeight = $('.header').height();
    var scrollThres = (17+24);//strip margin + padding
    var lastScroll = 0;
    var decideHeaderFixedTop = function(event) {
        var scrollValue = $(window).scrollTop();
        //this would simply fix header when scroll>41
        //if (scrollValue > scrollThres) {
        //now this checks for scroll direction (very simply, without delta)...
        //..EXCEPT when page is at top
        if (scrollValue < lastScroll && scrollValue >= scrollThres) {
            $('.header').addClass('fixed-top');
        } else {
            $('.header').removeClass('fixed-top');
        }
        lastScroll = scrollValue;
    };
    //$(window).on('scroll', debounce(decideHeaderFixedTop, 50));
    $(window).on('scroll', decideHeaderFixedTop);
    //on load
    decideHeaderFixedTop();

    function decideInputTagFilled(el){
        $(el).toggleClass('filled', !!$(el).val());
    };

    function decideFormDropdownFilled(el){
	      $(el).parents('.form-dropdown').toggleClass('filled', !!$(el).val());
    }

    $('body').on('blur', '.form-field input, .form-field textarea', function() {
	      decideInputTagFilled(this);
    });
    $('body').on('blur mousedown change', '.form-field select', function() {
        decideFormDropdownFilled(this);
    });
    // onload
    $('.form-field input').each(function(){
        decideInputTagFilled(this);
    });
    $('.form-field .form-dropdown select').each(function(){
        decideFormDropdownFilled(this);
    });


    if(window.is_ipad()){
        $('.share-btn').click(function(){
            $(this).toggleClass('open');
        });
        $('*').click(function(e){
            if(!$(e.target).hasClass('share-btn') && $(e.target).parents('.share-btn').length == 0){
                $('.share-btn').removeClass('open');
            }
        });
    } else {
        $('body').on('click', '.share-btn', function(){
            $(this).toggleClass('open');
        });
        $('body').click(function(e){
            if(!$(e.target).hasClass('share-btn') && $(e.target).parents('.share-btn').length == 0){
                $('.share-btn').removeClass('open');
            }
        });
    }


    var homeSliderSpeed = 8000;
    $('.home-slider-wrapper').each(function(i, el){
        var $w = $(el);
        var wrapperId = $(el).attr('id');

        $w.find('.home-texts-slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: true,
            //avoid stopping autoplay on hover etc
            pauseOnFocus: false,
            pauseOnHover: false,
            draggable: false,
            swipe: false
        });
        $w.find('.home-slider').slickResponsive({
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            centerMode: false,
            vertical: false,
            verticalSwiping: false,
            arrows: true,
            autoplay: true,
            fade: false,
            autoplaySpeed: homeSliderSpeed,
            asNavFor: '#' + wrapperId + ' ' + '.home-texts-slider',
            //avoid stopping autoplay on hover etc
            pauseOnFocus: false,
            pauseOnHover: false,
            draggable: false,
            swipe: false
        });
        trunk8ultimate('.home-slide-desc', 3);

        function countHomeSlides(){
            return $w.find('.home-slider .slick-slide:not(.slick-cloned)').length;
        }

        // this code fills the extra line e.g. when on slide 2 of 3, it's 66% (2/3) width
        /*
          function drawHomeSliderExtra(current, total){
          $('.home-slider-extra-line').animate({
          'width' : '' + Math.round(100*current/total) + '%'
          }, {
          duration: 400
          });
          $('.home-slider-extra-counter').text('' + current + '/' + total);
          }
          $('.home-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
          drawHomeSliderExtra(nextSlide + 1, countHomeSlides());
          });
          //on load
          drawHomeSliderExtra(1, countHomeSlides());
        */

        // this code is a sort of countdown until next autoplay event
        function startExtraLineAnimation(current, total){
            // extra lines are outside .home-slider-wrapper
            // $w.find
            $('.home-slider-extra-counter').text('' + current + '/' + total);
            // $w.find
            $('.home-slider-extra-line')
                .stop()
                .css('width', '0')
                .animate({
                    'width' : '100%'
                }, {
                    duration: homeSliderSpeed
                });
        }
        $w.find('.home-slider').on('afterChange', function(event, slick, currentSlide){
            var nextSlide = currentSlide + 1;
            if(nextSlide > countHomeSlides()){
                nextSlide = 1;
            }
            //don't fire for other (responsive) slides
            if($(event.currentTarget).is(":visible")){
                startExtraLineAnimation(nextSlide, countHomeSlides());
            }
        });
        startExtraLineAnimation(1, countHomeSlides());

    });
    // END each home slider

    $('.page-home-showcase-content').slickResponsive({
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        centerMode: false,
        vertical: false,
        verticalSwiping: false,
        arrows: true,
        autoplay: false,
        fade: true,
        autoplaySpeed: 8000,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        draggable: false,
        swipe: false
    });

    function countShowcaseSlides(){
        return $('.page-home-showcase-content .slick-slide:not(.slick-cloned)').length;
    }
    if(countShowcaseSlides() == 1){
        $('.page-home-showcase-item-details').addClass('has-only-one-item');
    }


    $('.footer-back-to-top').click(function(){
        $("html, body").animate({ scrollTop: 0 }, 800);
    });




    $('.post-slider').slickResponsive({
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        centerMode: false,
        vertical: false,
        verticalSwiping: false,
        arrows: true,
        autoplay: false,
        fade: true,
        autoplaySpeed: 8000,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        draggable: false,
        swipe: false
    });
    trunk8ultimate('.post-slide-title', 1);



    $('.page-video-slider').each(function(i, vs){
        $(vs).slickResponsive({
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            centerMode: false,
            vertical: false,
            verticalSwiping: false,
            arrows: true,
            autoplay: false,
            fade: true,
            autoplaySpeed: 8000,
            //avoid stopping autoplay on hover etc
            pauseOnFocus: false,
            pauseOnHover: false,
            draggable: false,
            swipe: false
        });
    });

    function countProductsVideoSlides(){
        return $('.page-video-slider .slick-slide:not(.slick-cloned)').length;
    }
    if(countProductsVideoSlides() == 1){
        $('.page-video-slider,.page-captions').addClass('has-only-one-item');
    }


    $('.page-contact-slider').slickResponsive({
        dots: false,
        infinite: false,//true
        speed: 800,
        slidesToShow: 1,
        centerMode: false,
        vertical: false,
        verticalSwiping: false,
        arrows: false,//true
        autoplay: false,
        fade: false,
        autoplaySpeed: 8000,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        draggable: false,
        swipe: false
    });


    trunk8ultimate('.page-about-captions-desc', 10);

    $('.page-about-slider').slickResponsive({
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        centerMode: false,
        vertical: false,
        verticalSwiping: false,
        arrows: true,
        autoplay: false,
        fade: false,
        autoplaySpeed: 8000,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        draggable: false,
        swipe: false
    });

    var enableTimelineSwipe = false;
    $('.timeline-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: false,
        //very important to turn off infinite - if there are multiple event sliders in .slick-cloned slides, then .timeline-nav-item-dots asNavFor won't work.
        infinite: false,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        draggable: false,
        swipe: enableTimelineSwipe
    });
    $('.timeline-nav').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.timeline-slider',
        dots: false,
        centerMode: true,
        focusOnSelect: true,
        arrows: false,
        infinite: false,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        draggable: false,
        swipe: enableTimelineSwipe
    });
    //this doesn't take into account when e.g. event2 of inactive year was clicked
    /*$('.timeline-nav').on('afterChange', function(event, slick, currentSlide, nextSlide){
        $($('.timeline-nav-item')[currentSlide]).find('.timeline-nav-item-dot').first().click();
        });*/
    function timelineSetDisabled(currentSlide){
        var c = $('.timeline-nav .slick-slide:not(.slick-cloned)').length;
        var $activeEvent = $('.timeline-nav-item-dot.active');
        if(currentSlide == 0 && !$activeEvent.prev().length){
            $('.timeline-nav-prev').addClass('disabled');
        } else {
            $('.timeline-nav-prev').removeClass('disabled');
        }
        if(currentSlide == c-1 && !$activeEvent.next().length){
            $('.timeline-nav-next').addClass('disabled');
        } else {
            $('.timeline-nav-next').removeClass('disabled');
        }
    };
    $('.timeline-nav').on('afterChange', function(event, slick, currentSlide, nextSlide){
        timelineSetDisabled(currentSlide);
    });
    timelineSetDisabled(0);
    $('.timeline-nav-item-dots').each(function(i, el){
        var year = $(el).attr('data-year');
        var selEvents = '.timeline-slide-events[data-year="'+year+'"]';
        var $events = $(selEvents);
        $events.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false,
            fade: false,
            //avoid stopping autoplay on hover etc
            pauseOnFocus: false,
            pauseOnHover: false,
            draggable: false,
            swipe: enableTimelineSwipe
        });
        /*$(el).slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            dots: false,
            centerMode: false,
            focusOnSelect: true,
            arrows: false,
            infinite: false,
            //avoid stopping autoplay on hover etc
            pauseOnFocus: false,
            pauseOnHover: false,
            asNavFor: selEvents
        });*/
    });
    $('body').on('click', '.timeline-nav-item-dot', function(e){
        $('.timeline-nav-item-dot').removeClass('active');
        $(this).addClass('active');
        var year = $(this).parent('.timeline-nav-item-dots').attr('data-year');
        var event = $(this).attr('data-event');
        //console.log(year, event);
        $('.timeline-slide-events[data-year="'+year+'"]').slick('slickGoTo', event);
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
    });
    $('body').on('click', '.timeline-nav-item-year', function(e){
        $(this).parent('.timeline-nav-item').find('.timeline-nav-item-dot').first().click();
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        return false;
    });
    $('body').on('click', '.timeline-nav-prev', function(e){
        var $currentDot = $('.timeline-nav-item-dot.active');
        var currentYear = $currentDot.parent('.timeline-nav-item-dots').attr('data-year');
        if($currentDot.prev().length){
            //switch to prev. event inside current year
            $currentDot.prev().click();
        } else {
            //switch to last event of prev. year
            //$('.timeline-nav').slick('slickPrev');
            $currentDot.parents('.slick-slide').first().prev().find('.timeline-nav-item-dot').last().click();
        }
    });
    $('body').on('click', '.timeline-nav-next', function(e){
        var $currentDot = $('.timeline-nav-item-dot.active');
        var currentYear = $currentDot.parent('.timeline-nav-item-dots').attr('data-year');
        if($currentDot.next().length){
            //switch to next. event inside current year
            $currentDot.next().click();
        } else {
            //switch to first event of next year
            //$('.timeline-nav').slick('slickNext');
            $currentDot.parents('.slick-slide').first().next().find('.timeline-nav-item-dot').first().click();
        }
    });
    $('.timeline-slider-wrapper').each(function(){
        //detect finger swipe inside timeline slider
        var $nextButton = $('.timeline-nav-next');
        var $prevButton = $('.timeline-nav-prev');
        var hammertime = new Hammer(document.querySelector('.timeline-slider-wrapper'), {});
        hammertime.on('swipe', function(ev) {
            //	console.log(ev);
            if (ev.direction == 4 /*go to prev*/) { $prevButton.trigger('click'); }
            if (ev.direction == 2 /*go to next*/) { $nextButton.trigger('click'); }
        });
    });


    //trunk8ultimate('.timeline-slide-desc p', 5);

    $('.mgmt-bios-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: true,
        infinite: false,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        draggable: false,
        swipe: false
    });
    $('.mgmt-slider').slickResponsive({
        dots: false,
        infinite: false,
        speed: 800,
        slidesToShow: 1,
        centerMode: false,
        vertical: false,
        verticalSwiping: false,
        arrows: true,
        autoplay: false,
        fade: false,
        autoplaySpeed: 8000,
        //avoid stopping autoplay on hover etc
        pauseOnFocus: false,
        pauseOnHover: false,
        asNavFor: '.mgmt-bios-slider',
        draggable: false,
        swipe: false
    });

        //we need 10 lines but need to specify 6
    var mgmtLines = {
        xxl: 17,
        xl: 17,
        lg: 9,
        md: 6,
        sm: 5,
        xs: 3
    };
    $('.mgmt-bios-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
        trunk8ultimate('.mgmt-slide-bio p', mgmtLines);
    });
    trunk8ultimate('.mgmt-slide-bio p', mgmtLines);


    trunk8ultimate('.item-square-product:not(.item-no-img):not(.item-square-product-relevant) .item-title', 2);
    trunk8ultimate('.item-square-product.item-no-img:not(.item-square-product-relevant) .item-title', 1);
    trunk8ultimate('.item-square-product .item-desc', 2);
    //needs redrawing, otherwise Chrome won't draw the titles for some reason
    $('.item-square-product-relevant .item-title').hide().show();

    trunk8ultimate('.item-blog-list .item-desc', 3);

    /*$('body').on('click', '.product-include-toggle', function(){
        $(this).parents('.product-include').toggleClass('open');
    });*/

    $('body').on('click', '.product-include', function(){
        $(this).toggleClass('open');
    });

    $('body').on('click', '.employee', function(){
        $(this).toggleClass('open');
    });

    $('body').on('click', '.form-multiple-item-header', function(e){
        if(e.target == e.currentTarget){
            $(this).parents('.form-multiple-item').addClass('open');
        }
    });
    $('body').on('click', '.form-multiple-item-close', function(e){
        $(this).parents('.form-multiple-item').toggleClass('open');
    });

    //emulate language switching on test domains
    if(-1 < ["localhost", "luka.ge"].indexOf(document.location.hostname)){
        $('.lang-ge').click(function(e){
            $('body').removeClass('en').removeClass('ru').addClass('ge');
            $('.lang li').removeClass('active');
            $(this).addClass('active');
            return false;
        });
        $('.lang-en').click(function(e){
            $('body').removeClass('ge').removeClass('ru').addClass('en');
            $('.lang li').removeClass('active');
            $(this).addClass('active');
            return false;
        });
        $('.lang-ru').click(function(e){
            $('body').removeClass('en').removeClass('ge').addClass('ru');
            $('.lang li').removeClass('active');
            $(this).addClass('active');
            return false;
        });
    }

});
